import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
  height: 100vh;

  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IntroText = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
  color: #272341;
`
const Title = styled.h1`
  font-size: 2.2rem;
  margin-top: 0;
  background-image: linear-gradient(135deg, #02aab0, #00cdac);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

const SeeMore = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.7rem;
  border-image: linear-gradient(135deg, #02aab0, #00cdac);
  border-width: 2px;
  border-style: solid;
  border-image-slice: 1;

  background-image: linear-gradient(135deg, #02aab0, #00cdac);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  :hover {
    cursor: pointer;
    background-color: green;
  }
`

interface IHeaderProps {
  introText: string
  title: string
  scrollTo: () => void
}

export const Header = ({ introText, title, scrollTo }: IHeaderProps) => {
  return (
    <Wrapper>
      <IntroText>{introText}</IntroText> <Title>{title}</Title>
      <SeeMore onClick={scrollTo}>Om oss</SeeMore>
    </Wrapper>
  )
}
