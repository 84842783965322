import React, { ReactNode } from "react"
import styled from "@emotion/styled"

import railWay from "../images/railway-track-2049394_1920.jpg"

const Wrapper = styled.div`
  background: linear-gradient(135deg, #02aab0, #00cdac);
  color: #fff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const Information = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 1rem;

  @media (max-width: 820px) {
    text-align: center;
  }
`

const Title = styled.h1`
  margin-top: 0;
`

const TextBox = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
`

const Container = styled.div`
  display: flex;
`

const Image = styled.img`
  margin: 1rem;
  min-width: 100px;
`

interface IAboutProps {
  paragraphs?: string[]
  children?: ReactNode
}

export const About = React.forwardRef<HTMLDivElement, IAboutProps>(
  ({ paragraphs }: IAboutProps, ref) => {
    return (
      <Wrapper ref={ref}>
        <Title>Om Oss</Title>
        <Container>
          <Information>
            {paragraphs?.map(p => (
              <TextBox key={p}>{p}</TextBox>
            ))}
          </Information>
        </Container>
      </Wrapper>
    )
  }
)
