import React from "react"
// import { Link } from "gatsby"
import styled from "@emotion/styled"

import { Header } from "../components/Header"
import { About } from "../components/About"
import { introParagraphs } from "../content/siteText"
import { Contact } from "../components/Contact"
import { Copyright } from "../components/Copyright"

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const IndexPage = () => {
  const introRef = React.createRef<HTMLDivElement>()

  const scrollToView = () => {
    introRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <>
      <Header
        introText="Välkommen till"
        title="SB Consultants"
        scrollTo={scrollToView}
      />
      <Wrapper>
        <About paragraphs={introParagraphs} ref={introRef} />
        <Contact email="info@sb-consultants.se" />
        <Copyright year="2020" company="rstotlz" />
      </Wrapper>
    </>
  )
}

export default IndexPage
