import styled from "@emotion/styled"
import React from "react"

const Label = styled.span`
  background: linear-gradient(235deg, #02aab0, #00cdac);
  width: 100vw;
  color: #fff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
`

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  :hover {
    color: #272341;
  }
`

interface ICopyright {
  year: string
  company: string
}

export const Copyright = ({ year, company }: ICopyright) => {
  return (
    <Label>
      Copyright&nbsp;&#169;&nbsp;{year}&nbsp;by&nbsp;
      <Link href="mailto:robinstoltz90@gmail.com">{company}</Link>
    </Label>
  )
}
