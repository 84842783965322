import React from "react"
import styled from "@emotion/styled"

interface IContactProps {
  email: string
}

const Wrapper = styled.div`
  background-color: #fff;
  max-width: 100vw;
  min-height: 4rem;
  margin: 1rem 2rem;
`

const Title = styled.h1`
  text-align: center;
  color: #272341;
  margin-top: 0;
`

const Details = styled.div`
  display: flex;
  justify-content: center;
`

const Email = styled.a`
  color: #272341;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

export const Contact = ({ email }: IContactProps) => {
  return (
    <Wrapper>
      <Title>Kontakt</Title>
      <Details>
        Email:&nbsp;<Email href={`mailto:${email}`}>{email}</Email>
      </Details>
    </Wrapper>
  )
}
